import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';

import {Form} from '@evag/ec-react';

import '@evag/ec-react/dist/main.css';

import CampaignFeedback from '../components/CampaignFeedback';
import CampaignForm from '../components/CampaignForm';
import Hero from '../components/Hero';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

export default function render({data}) {
  CampaignForm.channelId = data.site.meta.channel;

  return (
    <Layout>
      <SEO title={data.site.meta.title} />

      <Hero
        background={data.hero.childImageSharp.fluid}
        logo={data.logo.childImageSharp.fluid}
        juntos={data.juntos.childImageSharp.fluid}
        channel={data.site.meta.channel}
      />

      <main className="main">
        <div className="wrap">
          <p>As universidades e institutos públicos de pesquisa estão provando que a solidariedade e a ciência pensada para o coletivo são fundamentais no combate à pandemia do coronavírus, que vem se alastrando no nosso país e no mundo.</p>

          <p>O Movimento Juntos!, em parceria com a co-Deputada Estadual Mônica Seixas (PSOL) da Bancada Ativista, protocolou um Projeto de Lei (PL 290/2020) para o Estado de São Paulo que cria um fundo público de investimento em pesquisas relacionadas ao Covid-19, com o objetivo de ampliar as verbas destinadas às universidades e institutos de pesquisa, como a FURB, FAPESP, Instituto Butantan e outros.</p>

          <p>Nesta crise do Coronavirus, diferentemente do que prega o governo genocida de Bolsonaro, tem ficado claro o enorme papel que as universidades públicas têm cumprido, através do desenvolvimento de ciência e de pesquisa, para a garantia dos interesses da sociedade. É obrigação do Estado garantir que essas universidades e institutos tenham todas as condições para o desenvolvimento das suas pesquisas.</p>

          <p><strong>Leia o Projeto de Lei na íntegra:</strong><br />
            <a href="https://www.al.sp.gov.br/spl/2020/04/Propositura/1000323473_1000371003_Propositura.doc">1000323473_1000371003_Propositura.doc</a></p>
        </div>
      </main>

      <div id="sign" className="signatures">
        <div className="wrap">
          <h2 className="signatures-title">Assine a petição</h2>

          <Form
            forms={[
              CampaignForm,
              {
                ...CampaignFeedback,
                title: data.site.meta.title,
                url: data.site.meta.url,
              },
            ]}
          />
        </div>
      </div>
    </Layout>
  );
}

render.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
        channel
        url
      }
    }

    hero: file(relativePath: { eq: "og-image.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    juntos: file(relativePath: { eq: "juntos.png" }) {
      childImageSharp {
        fluid(maxWidth: 240) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
