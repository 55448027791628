import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import Button from './Button';
import CountSignatures from './CountSignatures';

import styles from './Hero.module.scss';

const Hero = ({background, channel, juntos, logo}) => (
  <div className={styles.container}>
    <Img className={styles.image} fluid={background} style={{position: false}} />
    <div className={styles.overlay}>
      <div className="wrap">
        <Img className={styles.juntos} fluid={juntos} style={{position: false}} />
        <Img className={styles.logo} fluid={logo} style={{position: false}} />
        <CountSignatures channel={channel} />
        <p className={styles.description}><Button to="#sign">Assine agora</Button></p>
      </div>
    </div>
  </div>
);

Hero.propTypes = {
  background: PropTypes.object,
  juntos: PropTypes.object,
  logo: PropTypes.object,
  channel: PropTypes.string,
};

export default Hero;
